Px.Editor.Page = class Page extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.DragAndDropToPageMixin
) {

  template() {
    const page = this.data.page;

    return Px.template`
      <svg class="px-page"
           data-page-id="${page.id}"
           ${this.sizeAttributes}
           viewBox="${this.viewBoxAttribute}"
           pointer-events="${this.previewMode ? 'none' : 'auto'}"
           data-ondragover="onNativeDragOver"
           data-ondrop="onNativeDrop"
           ${this.styleTransformAttribute}
           ${this.preserveAspectRatioAttribute}
           ${this.pointerHandlers}
        >

        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            <defs>
              <clipPath id="${this.clipPathId}">
                <rect x="0"
                      y="0"
                      width="${page.width}"
                      height="${page.height}"
                />
              </clipPath>
            </defs>
          `;
        })}

        ${Px.if(this.data.render_content, () => {
          return Px.template`
            <defs>
              ${Px.if(this.masksrc, () => {
                return Px.template`
                  <mask id="${this.maskId}" x="0" y="0" width="100%" height="100%">
                    <image xlink:href="${this.masksrc}"
                           x="0"
                           y="0"
                           width="${page.width}"
                           height="${page.height}"
                    />
                  </mask>
                `;
              })}
            </defs>
            <g ${this.maskAttribute}>
              ${Px.if(this.renderBgColor, () => {
                return Px.template`
                  <rect class="px-page-background"
                        x="0"
                        y="0"
                        width="${page.width}"
                        height="${page.height}"
                        fill="${Px.Util.colorForDisplay(page.bgcolor || '#fff')}"
                  />
                `;
              })}
              ${Px.if(this.bgsrc, () => {
                return Px.template`
                  <image xlink:href="${this.bgsrc}"
                         x="0"
                         y="0"
                         width="${page.width}"
                         height="${page.height}"
                         preserveAspectRatio="xMidYMid${page.bgfill ? ' slice' : ''}"
                  />
                `;
              })}

              ${this.renderedElements.map(element => {
                return this.renderChild(this.componentType(element), element.unique_id, this.elementProps(element));
              })}

              ${Px.if(page.has_bleed && !this.data.store.crop_bleed && !(this.previewMode || this.data.mobile_mode), () => {
                return Px.template`
                  <rect class="px-bleed"
                        pointer-events="none"
                        x="${page.bleed_l}"
                        y="${page.bleed_t}"
                        width="${page.width - (page.bleed_l + page.bleed_r)}"
                        height="${page.height - (page.bleed_t + page.bleed_b)}"
                        stroke-width="${this.inSvgUnits(1)}"
                  />
                  <text class="px-bleed"
                        pointer-events="none"
                        x="${page.bleed_l + this.inSvgUnits(10)}"
                        y ="${page.bleed_t}"
                        font-size="${this.inSvgUnits(10)}"
                        stroke-width="${this.inSvgUnits(0.75)}"
                        dominant-baseline="middle">
                    ${Px.t('bleed')}
                  </text>
                `;
              })}

              ${Px.if(page.has_margin && !(this.previewMode || this.data.mobile_mode), () => {
                return Px.template`
                  <rect class="px-margin"
                        pointer-events="none"
                        x="${page.bleed_l + page.margin_l}"
                        y="${page.bleed_t + page.margin_t}"
                        width="${page.width - (page.bleed_l + page.bleed_r + page.margin_l + page.margin_r)}"
                        height="${page.height - (page.bleed_t + page.bleed_b + page.margin_t + page.margin_b)}"
                        stroke-width="${this.inSvgUnits(1)}"
                  />
                  <text class="px-margin"
                        pointer-events="none"
                        x="${page.bleed_l + page.margin_l + this.inSvgUnits(10)}"
                        y ="${page.bleed_t + page.margin_t}"
                        font-size="${this.inSvgUnits(10)}"
                        stroke-width="${this.inSvgUnits(0.75)}"
                        dominant-baseline="middle">
                    ${Px.t('safe area')}
                  </text>
                `;
              })}

              ${Px.if(!(this.previewMode || this.data.mobile_mode), () => {
                return Px.template`
                  <g pointer-events="none">
                    ${Px.if(this.data.store.debug_gutter && this.gutterPosition, () => {
                      const x = this.gutterPosition === 'left' ? page.gutter : page.width - page.gutter;
                      return Px.template`
                        <line x1="${x}"
                              y1="0"
                              x2="${x}"
                              y2="${this.inSvgUnits(this.height)}"
                              stroke-width="${this.alignmentHighlightWidth}"
                              stroke="var(--sun-yellow)"
                        />
                      `;
                    })}
                    ${this.gridLines.map(line => {
                      if (line.hasOwnProperty('x')) {
                        return Px.template`
                          <line x1="${line.x}"
                                y1="0"
                                x2="${line.x}"
                                y2="${this.inSvgUnits(this.height)}"
                                opacity="0.5"
                                stroke-width="${this.alignmentHighlightWidth}"
                                stroke="var(--pixfizz-blue)"
                          />
                        `;
                      } else {
                        return Px.template`
                          <line x1="0"
                                y1="${line.y}"
                                x2="${this.inSvgUnits(this.width)}"
                                y2="${line.y}"
                                opacity="0.5"
                                stroke-width="${this.alignmentHighlightWidth}"
                                stroke="var(--pixfizz-blue)"
                          />
                        `;
                      }
                    })}
                    ${this.alignmentHighlights.map(highlight => {
                      if (highlight.hasOwnProperty('x')) {
                        return Px.template`
                          <line x1="${highlight.x}"
                                y1="0"
                                x2="${highlight.x}"
                                y2="${this.inSvgUnits(this.height)}"
                                stroke-width="${this.alignmentHighlightWidth}"
                                stroke="#ff5216"
                          />
                        `;
                      } else {
                        return Px.template`
                          <line x1="0"
                                y1="${highlight.y}"
                                x2="${this.inSvgUnits(this.width)}"
                                y2="${highlight.y}"
                                stroke-width="${this.alignmentHighlightWidth}"
                                stroke="#ff5216"
                          />
                        `;
                      }
                    })}
                  </g>
                `;
              })}
            </g>
          `;
        }).elseIf(this.data.render_controls, () => {
          return this.renderedElements.map(element => {
            return this.renderChild(this.componentType(element), element.unique_id, this.elementProps(element));
          });
        })}
      </svg>
    `;
  }

  get dataProperties() {
    return {
      page: {required: true},
      store: {required: true},
      scale: {required: false, std: null},
      available_width: {required: false, std: null},
      available_height: {required: false, std: null},
      preserve_aspect_ratio: {required: false, std: true},
      preview_mode: {std: false},
      render_content: {std: true},
      render_controls: {std: true},
      mobile_mode: {std: false},
      hide_placeholders: {std: false},
      absolute_size: {std: true},
      transparent_bgcolor: {std: null},
      page_stack: {std: mobx.observable.array()}
    };
  }

  static get computedProperties() {
    return {
      isInlinePage: function() {
        return this.data.page_stack.length > 0;
      },
      clipPathId: function() {
        return `page-clippath-${this._component_id}`;
      },
      maskId: function() {
        return `page-mask-${this._component_id}`;
      },
      maskAttribute: function() {
        if (this.masksrc) {
          return Px.raw(`mask="url(#${this.maskId})"`);
        }
        return '';
      },
      styleTransformAttribute: function() {
        if (this.isAutorotatedCutPrint) {
          return Px.raw(`style="transform:rotate(-90deg)"`);
        }
        return '';
      },
      preserveAspectRatioAttribute: function() {
        if (this.data.preserve_aspect_ratio) {
          return '';
        }
        return Px.raw('preserveAspectRatio="none"');
      },
      previewMode: function() {
        return this.data.preview_mode;
      },
      renderBgColor: function() {
        const transparent_bgcolor = this.data.transparent_bgcolor;
        const page_bgcolor = this.data.page.bgcolor || '#FFFFFF';
        if (transparent_bgcolor && transparent_bgcolor.toUpperCase() === page_bgcolor.toUpperCase()) {
          return false;
        }
        return true;
      },
      renderedElements: function() {
        let elements = this.data.page.elements.filter(element => {
          let is_renderable = element.is_in_viewport || element.is_selected;
          if (is_renderable && element.pdf_layer) {
            const layer = this.data.store.theme.getPdfLayerByName(element.pdf_layer);
            is_renderable = !layer || layer.enabled;
          }
          return is_renderable;
        });
        if (this.data.hide_placeholders) {
          elements =  elements.filter(element => !element.placeholder || element.show_on_preview);
        }
        return elements;
      },
      sizeAttributes: function() {
        if (!this.data.absolute_size) {
          return '';
        }
        return Px.raw(`width="${this.width}" height="${this.height}"`);
      },
      pointerHandlers: function() {
        if (this.previewMode || this.data.mobile_mode) {
          return '';
        }
        return Px.raw('data-onmousedown="selectPage" data-ontouchstart="selectPage"');
      },
      sizeCalculator: function() {
        return Px.Editor.Page.SizeCalculator.make(this.data.store, this.data.page, {
          scale: this.data.scale,
          available_width: this.data.available_width,
          available_height: this.data.available_height
        });
      },
      width: function() {
        const width = this.data.preserve_aspect_ratio ? null : this.data.available_width;
        return width || this.sizeCalculator.width();
      },
      height: function() {
        const height = this.data.preserve_aspect_ratio ? null : this.data.available_height;
        return height || this.sizeCalculator.height();
      },
      scale: function() {
        return this.sizeCalculator.scale();
      },
      gutterPosition: function() {
        const page = this.data.page;
        // Only sets with two pages can have gutters.
        if (page.set && page.set.pages.length !== 2) {
          return null;
        }
        return page.position === 0 ? 'right' : 'left';
      },
      viewBox: function() {
        const store = this.data.store;
        const page = this.data.page;
        const gutter = store.debug_gutter ? 0 : page.gutter;
        const viewBoxW = page.viewBoxWidth(store.crop_bleed, store.debug_gutter);
        const viewBoxH = page.viewBoxHeight(store.crop_bleed);
        let viewBoxX, viewBoxY;
        if (store.crop_bleed) {
          viewBoxX = (this.gutterPosition === 'left' && gutter) ? gutter : page.bleed_l;
          viewBoxY = page.bleed_t;
        } else {
          viewBoxX = this.gutterPosition === 'left' ? gutter : 0;
          viewBoxY = 0;
        }
        return [viewBoxX, viewBoxY, viewBoxW, viewBoxH];
      },
      viewBoxAttribute: function() {
        return this.viewBox.join(' ');
      },
      bgsrc: function() {
        const page = this.data.page;
        if (!page.src) {
          return null;
        }
        const image = this.data.store.images.get(page.src);
        const params = {
          size: this.inPixels(Math.max(page.width, page.height))
        };
        if (page.srccolor) {
          const color = page.srccolor.replace('#', '');
          if (color !== '000000') {
            params.color = color;
          }
        }
        return image.src(params);
      },
      masksrc: function() {
        const page = this.data.page;
        if (!page.mask) {
          return null;
        }
        const image = this.data.store.images.get(page.mask);
        const params = {
          size: this.inPixels(Math.max(page.width, page.height))
        };
        return image.src(params);
      },
      gridLines: function() {
        if (this.isInlinePage) {
          return [];
        }
        const grid_spec = this.data.store.ui.grid_spec;
        return this.data.page.gridLines(grid_spec.vblocks, grid_spec.hblocks);
      },
      alignmentHighlights: function() {
        const page = this.data.page;
        const selected_element = this.data.store.selected_element;
        const grid = this.data.store.ui.grid_spec;
        let alignments = [];
        if (Px.config.element_alignment && selected_element) {
          if (selected_element.page === page) {
            alignments = selected_element.elementAlignments(this.inSvgUnits(1), grid);
          } else if (selected_element.two_page_spread_clone && selected_element.two_page_spread_clone.page === page) {
            alignments = selected_element.two_page_spread_clone.elementAlignments(this.inSvgUnits(1), grid);
          }
        }
        return alignments;
      },
      alignmentHighlightWidth: function() {
        return this.inSvgUnits(1);
      },
      isAutorotatedCutPrint: function() {
        return this.data.store.isAutorotatedCutPrint(this.data.page);
      }
    };
  }

  componentType(element) {
    return Px.Editor.BaseElementComponent.getClass(element.type);
  }

  elementProps(element) {
    return {
      element: element,
      scale: this.scale,
      page_clip_path_id: this.clipPathId,
      preview_mode: this.data.preview_mode,
      mobile_mode: this.data.mobile_mode,
      hide_placeholders: this.data.hide_placeholders,
      render_content: this.data.render_content,
      render_controls: this.data.render_controls,
      page_stack: this.data.page_stack.concat([this.data.page])
    };
  }

  inPixels(len) {
    return len * this.scale;
  }

  inSvgUnits(pixels) {
    return this.scale > 0 ? pixels / this.scale : 0;
  }

  // --------------
  // Event handlers
  // --------------

  selectPage(evt) {
    const store = this.data.store;
    mobx.transaction(() => {
      store.selectPage(this.data.page);
      store.selectElement(null);
      store.ui.image_swap_source = null;
      // If any element other than the body is currently focused, deselect it.
      if (document.activeElement && document.activeElement !== document.body) {
        document.activeElement.blur();
      }
    });
  }

  onNativeDragOver(evt) {
    evt.preventDefault();
  }

  onNativeDrop(evt) {
    evt.preventDefault();
    const drop_target = this.getDropTarget(evt);
    const files = Px.Util.getDataTransferFiles(evt);
    if (drop_target && files && files.length > 0) {
      if (drop_target === this.data.page &&
          files[0].type === 'application/pdf' &&
          (Px.config.pdf_imports || Px.config.advanced_edit_mode)) {
        this.onNativePdfDrop(evt, files);
      } else {
        this.onNativeImageDrop(evt, files);
      }
    }
  }

  onNativeImageDrop(evt, files) {
    const image_files = Px.LocalFiles.Validation.filterAndValidateFiles(files);
    if (image_files) {
      const store = this.data.store;
      store.galleries.project.importImage(image_files[0], image => {
        const image_store = store.images;
        if (!image_store.get(image.id)) {
          image_store.register(image.id, image.data);
        }
        // Have to go through main image store to make sure local ID gets derefferenced,
        // if image is already uploaded.
        const registered_image = image_store.get(image.id);
        this.dropImageToTarget(evt, registered_image.id, {onAfterDrop: image => store.selectElement(image)});
      });
    }
  }

  onNativePdfDrop(evt, files) {
    const store = this.data.store;
    const file = files[0];

    const MAX_PDF_FILE_SIZE_MB = 125;
    if (file.size > MAX_PDF_FILE_SIZE_MB * 1024 * 1024) {
      msg = Px.t('The PDF file is too large. Maximum allowed file size is {{size}} MB.');
      alert(msg.replace(/\{\{size\}\}/g, MAX_PDF_FILE_SIZE_MB));
      return;
    }

    this.makeModal(Px.Editor.PdfImportModal, {
      store: this.data.store,
      page: this.data.page,
      pdf_file: file
    });
  }

};

// Moving some size calculations into a separate class so that we can reuse them from PageDisplay.
Px.Editor.Page.SizeCalculator = class SizeCalculator extends Px.Base {

  constructor(store, page, dim_opts) {
    super();
    this.store = store;
    this.page = page;
    this.predefined_scale = dim_opts.hasOwnProperty('scale') ? dim_opts.scale : null;
    this.available_width = dim_opts.hasOwnProperty('available_width') ? dim_opts.available_width : null;
    this.available_height = dim_opts.hasOwnProperty('available_height') ? dim_opts.available_height : null;
    if (this.predefined_scale === null) {
      if (this.available_width === null || this.available_height === null) {
        throw new Error("Either 'scale' or both 'available_width' and 'available_height' properties must be given");
      }
    }
  }

  isAutorotatedCutPrint() {
    return this.store.isAutorotatedCutPrint(this.page);
  }

  scale() {
    let scale = 0;
    if (this.predefined_scale !== null) {
      scale = this.predefined_scale;
    } else {
      const crop_bleed = this.store.crop_bleed;
      if (this.isAutorotatedCutPrint()) {
        scale = this.page.optimalScale(this.available_height, this.available_width, crop_bleed);
      } else {
        scale = this.page.optimalScale(this.available_width, this.available_height, crop_bleed);
      }
    }
    return Math.max(0, scale);
  }

  width() {
    return this.page.viewBoxWidth(this.store.crop_bleed, this.store.debug_gutter) * this.scale();
  }

  height() {
    return this.page.viewBoxHeight(this.store.crop_bleed) * this.scale();
  }

};
